import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "selectors/usersSelectors";
import styled from "styled-components";
import StyledHeader from "components/designSystems/appsmith/StyledHeader";
import type { AppState } from "ee/reducers";
import type { User } from "constants/userConstants";
import { useIsMobileDevice } from "utils/hooks/useDeviceDetect";
import { getTemplateNotificationSeenAction } from "actions/templateActions";
import { shouldShowLicenseBanner } from "ee/selectors/tenantSelectors";
import { Banner } from "ee/utils/licenseHelpers";
import bootIntercom from "utils/bootIntercom";
import EntitySearchBar from "pages/common/SearchBar/EntitySearchBar";
import { Switch, Tooltip } from "@appsmith/ads";
import { getIsAnvilLayoutEnabled } from "layoutSystems/anvil/integrations/selectors";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import { setFeatureFlagOverrideValues } from "utils/storage";
import { updateFeatureFlagOverrideAction } from "actions/featureFlagActions";
// zuora changes
import { BuilderHeader, Tabs, Tab } from "zds";
import {
  APPLICATIONS_URL,
  TEMPLATES_PATH,
  ZTEMPLATES_PATH,
} from "constants/routes";
import history from "utils/history";

const StyledPageHeader = styled(StyledHeader)<{
  hideShadow?: boolean;
  isMobile?: boolean;
  showSeparator?: boolean;
  isBannerVisible?: boolean;
}>`
  justify-content: space-between;
  background: var(--ads-v2-color-bg);
  height: 56px;
  color: var(--ads-v2-color-bg);
  background-color: #041b2e;
  position: fixed;
  top: 0;
  z-index: var(--ads-v2-z-index-9);
  border-bottom: 1px solid var(--ads-v2-color-border);
  ${({ isMobile }) =>
    isMobile &&
    `
    padding: 0 12px;
    padding-left: 10px;
    `};
  ${({ isBannerVisible, isMobile }) =>
    isBannerVisible ? (isMobile ? `top: 70px;` : `top: 40px;`) : ""};

  /* intentionally "hacky" approach to show the Anvil toggle in the header. This will be removed once all features work well with Anvil */
  & .ads-v2-switch {
    display: block;
    width: 100%;
    position: absolute;
    left: 175px;
    top: 12px;
    width: 30px;
    & > label {
      min-width: 0;
      flex-direction: row-reverse;
    }
  }
`;

interface PageHeaderProps {
  user?: User;
  hideShadow?: boolean;
  showSeparator?: boolean;
  hideEditProfileLink?: boolean;
}

export function PageHeader(props: PageHeaderProps) {
  const { user } = props;
  const dispatch = useDispatch();

  const isMobile = useIsMobileDevice();
  const [selectedTab, setSelectedTab] = React.useState("Apps");

  useEffect(() => {
    dispatch(getTemplateNotificationSeenAction());
  }, []);

  useEffect(() => {
    bootIntercom(user);
  }, [user?.email]);

  const showBanner = useSelector(shouldShowLicenseBanner);
  const isHomePage = useRouteMatch("/applications")?.isExact;
  const isLicensePage = useRouteMatch("/license")?.isExact;
  const isAnvilEnabled = useSelector(getIsAnvilLayoutEnabled);
  const shouldShowAnvilToggle = useFeatureFlag(
    FEATURE_FLAG.release_anvil_toggle_enabled,
  );

  /*
    If Anvil toggle is enabled, the switch allows us to enable or disable Anvil
    We pass the anvil feature's value via the toggle. We also passthrough the original
    anvil toggle feature flag value as-is.
  */
  function handleAnvilToggle(isSelected: boolean) {
    const featureFlags = {
      release_anvil_enabled: isSelected,
      release_anvil_toggle_enabled: shouldShowAnvilToggle,
    };

    dispatch(updateFeatureFlagOverrideAction(featureFlags));
    setFeatureFlagOverrideValues(featureFlags);
  }

  const rootDiv: HTMLElement | null = document.getElementById("z_embed_root");
  if (rootDiv?.dataset?.pageId != null) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <>
      <Banner />
      <BuilderHeader
        actions={[
          {
            icon: "more_vert",
            label: "More",
            listChildren: [],
          },
        ]}
        centerSlot={
          <div className={"task-modal-tabs"}>
            <Tabs
              a11yLabel={undefined}
              dsOnChange={(event, value) => {
                setSelectedTab(value);
                if (value === "Apps") {
                  history.push(APPLICATIONS_URL);
                } else if (value === "ZTemplates") {
                  history.push(ZTEMPLATES_PATH);
                } else {
                  history.push(TEMPLATES_PATH);
                }
              }}
              value={selectedTab}
            >
              <Tab
                aria-controls={undefined}
                id={"apps-tab"}
                label="Extensions"
                value={"Apps"}
              />
              {/* <Tab
                aria-controls={undefined}
                id={"templates-modal-tab"}
                label="Templates"
                value={"Templates"}
              /> */}
              <Tab
                aria-controls={undefined}
                id={"templates-modal-tab"}
                label="Templates"
                value={"ZTemplates"}
              />
            </Tabs>
          </div>
        }
        description=""
        e2e="myBuilderHeader"
        href="/"
        title="Extension Studio"
      />
      <StyledPageHeader
        data-testid="t--appsmith-page-header"
        hideShadow={props.hideShadow || false}
        isBannerVisible={showBanner && (isHomePage || isLicensePage)}
        isMobile={isMobile}
        showSeparator={props.showSeparator || false}
      >
        {
          // Based on a feature flag, show the switch that enables/disables Anvil
          shouldShowAnvilToggle && (
            <Switch isSelected={isAnvilEnabled} onChange={handleAnvilToggle}>
              <Tooltip content="Toggles Anvil Layout System" trigger="hover">
                <b>&alpha;</b>
              </Tooltip>
            </Switch>
          )
        }
        <EntitySearchBar user={user} />
      </StyledPageHeader>
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  user: getCurrentUser(state),
  hideShadow: state.ui.theme.hideHeaderShadow,
  showSeparator: state.ui.theme.showHeaderSeparator,
});

export default connect(mapStateToProps)(PageHeader);
