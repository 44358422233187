import React, { Suspense, useEffect } from "react";
import history from "utils/history";
import AppHeader from "ee/pages/common/AppHeader";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import {
  ADMIN_SETTINGS_CATEGORY_PATH,
  ADMIN_SETTINGS_PATH,
  ALL_EXTENSIONS_URL,
  APPLICATIONS_URL,
  BASE_EXTENSIONS_URL,
  BASE_URL,
  BUILDER_CUSTOM_PATH,
  BUILDER_PATCH_PATH,
  BUILDER_PATH,
  BUILDER_PATH_DEPRECATED,
  CUSTOM_WIDGETS_DEPRECATED_EDITOR_ID_PATH,
  CUSTOM_WIDGETS_EDITOR_ID_PATH,
  CUSTOM_WIDGETS_EDITOR_ID_PATH_CUSTOM,
  PROFILE,
  SIGNUP_SUCCESS_URL,
  USERS_URL,
  USER_AUTH_URL,
  VIEWER_CUSTOM_PATH,
  VIEWER_PATCH_PATH,
  VIEWER_PATH,
  VIEWER_PATH_DEPRECATED,
  WORKSPACE_URL,
  ZAUTH_LOGIN_URL,
  ZBASE_LOGIN_URL,
  ZTEMPLATES_PATH,
} from "constants/routes";
import WorkspaceLoader from "pages/workspace/loader";
import ApplicationListLoader from "pages/Applications/loader";
import EditorLoader from "pages/Editor/loader";
import AppViewerLoader from "pages/AppViewer/loader";
import LandingScreen from "../LandingScreen";
import UserAuth from "pages/UserAuth";
import Users from "pages/users";
import ErrorPage from "pages/common/ErrorPage";
import PageNotFound from "pages/common/ErrorPages/PageNotFound";
import PageLoadingBar from "pages/common/PageLoadingBar";
import ErrorPageHeader from "pages/common/ErrorPageHeader";
import { useDispatch, useSelector } from "react-redux";

import * as Sentry from "@sentry/react";
import { getSafeCrash, getSafeCrashCode } from "selectors/errorSelectors";
import UserProfile from "pages/UserProfile";
import SettingsLoader from "pages/AdminSettings/loader";
import SignupSuccess from "pages/setup/SignupSuccess";
import type { ERROR_CODES } from "ee/constants/ApiConstants";
import { getCurrentUser as getCurrentUserSelector } from "selectors/usersSelectors";
import { getTenantPermissions } from "ee/selectors/tenantSelectors";
import useBrandingTheme from "utils/hooks/useBrandingTheme";
import RouteChangeListener from "RouteChangeListener";
import { initCurrentPage } from "../actions/initActions";
import Walkthrough from "components/featureWalkthrough";
import ProductAlertBanner from "components/editorComponents/ProductAlertBanner";
import { getAdminSettingsPath } from "ee/utils/BusinessFeatures/adminSettingsHelpers";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import CustomWidgetBuilderLoader from "pages/Editor/CustomWidgetBuilder/loader";
import { getIsConsolidatedPageLoading } from "selectors/ui";
import { useFeatureFlagOverride } from "utils/hooks/useFeatureFlagOverride";
import Extensions from "pages/Extensions";
import Zextensions from "Zextensions";
import ZtemplatesListLoader from "pages/Ztemplates/loader";

export const SentryRoute = Sentry.withSentryRouting(Route);

export const loadingIndicator = <PageLoadingBar />;

export function Routes() {
  const user = useSelector(getCurrentUserSelector);
  const tenantPermissions = useSelector(getTenantPermissions);
  const isFeatureEnabled = useFeatureFlag(FEATURE_FLAG.license_gac_enabled);

  let fallbackPage;
  const rootDiv: HTMLElement | null = document.getElementById("z_embed_root");
  if (rootDiv?.dataset?.pageId != null) {
    fallbackPage = AppViewerLoader;
  } else {
    fallbackPage = PageNotFound;
  }

  useFeatureFlagOverride();

  return (
    <Switch>
      <SentryRoute component={LandingScreen} exact path={BASE_URL} />
      <SentryRoute component={WorkspaceLoader} path={WORKSPACE_URL} />
      <SentryRoute component={Users} exact path={USERS_URL} />
      <SentryRoute component={UserAuth} path={USER_AUTH_URL} />
      <SentryRoute component={Extensions} exact path={ALL_EXTENSIONS_URL} />
      <SentryRoute component={Zextensions} exact path={BASE_EXTENSIONS_URL} />
      <Redirect exact from={ZBASE_LOGIN_URL} to={ZAUTH_LOGIN_URL} />
      <SentryRoute
        component={ApplicationListLoader}
        exact
        path={APPLICATIONS_URL}
      />
      <SentryRoute component={SignupSuccess} exact path={SIGNUP_SUCCESS_URL} />
      <SentryRoute component={UserProfile} path={PROFILE} />
      <SentryRoute component={ZtemplatesListLoader} path={ZTEMPLATES_PATH} />
      <Redirect
        exact
        from={ADMIN_SETTINGS_PATH}
        to={
          !user
            ? ADMIN_SETTINGS_PATH
            : getAdminSettingsPath(
                isFeatureEnabled,
                user?.isSuperUser || false,
                tenantPermissions,
              )
        }
      />
      <SentryRoute
        component={SettingsLoader}
        exact
        path={ADMIN_SETTINGS_CATEGORY_PATH}
      />
      <SentryRoute
        component={CustomWidgetBuilderLoader}
        exact
        path={CUSTOM_WIDGETS_DEPRECATED_EDITOR_ID_PATH}
      />
      <SentryRoute component={EditorLoader} path={BUILDER_PATH_DEPRECATED} />
      <SentryRoute component={AppViewerLoader} path={VIEWER_PATH_DEPRECATED} />
      <SentryRoute
        component={CustomWidgetBuilderLoader}
        exact
        path={CUSTOM_WIDGETS_EDITOR_ID_PATH}
      />
      <SentryRoute
        component={CustomWidgetBuilderLoader}
        exact
        path={CUSTOM_WIDGETS_EDITOR_ID_PATH_CUSTOM}
      />
      {/*
       * Note: When making changes to the order of these paths
       * Be sure to check if it is sync with the order of checks in getUpdatedRoute helper method
       * Context: https://github.com/appsmithorg/appsmith/pull/19833
       */}
      <SentryRoute component={EditorLoader} path={BUILDER_PATH} />
      <SentryRoute component={EditorLoader} path={BUILDER_CUSTOM_PATH} />
      <SentryRoute component={AppViewerLoader} path={VIEWER_PATH} />
      <SentryRoute component={AppViewerLoader} path={VIEWER_CUSTOM_PATH} />
      {/*
       * End Note: When making changes to the order of the paths above
       */}
      <Redirect from={BUILDER_PATCH_PATH} to={BUILDER_PATH} />
      <Redirect from={VIEWER_PATCH_PATH} to={VIEWER_PATH} />
      <SentryRoute component={fallbackPage} />
    </Switch>
  );
}

export default function AppRouter() {
  const safeCrash: boolean = useSelector(getSafeCrash);
  const safeCrashCode: ERROR_CODES | undefined = useSelector(getSafeCrashCode);
  const isConsolidatedPageLoading = useSelector(getIsConsolidatedPageLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initCurrentPage());
  }, []);

  useBrandingTheme();

  const isLoading = isConsolidatedPageLoading;

  // hide the top loader once the tenant is loaded
  useEffect(() => {
    if (!isLoading) {
      const loader = document.getElementById("loader") as HTMLDivElement;

      if (loader) {
        loader.style.width = "100vw";
        setTimeout(() => {
          loader.style.opacity = "0";
        });
      }
    }
  }, [isLoading]);

  if (isLoading) return null;

  return (
    <Router history={history}>
      <Suspense fallback={loadingIndicator}>
        <RouteChangeListener />
        {safeCrash && safeCrashCode ? (
          <>
            <ErrorPageHeader />
            <ErrorPage code={safeCrashCode} />
          </>
        ) : (
          <>
            <Walkthrough>
              <AppHeader />
              <Routes />
            </Walkthrough>
            <ProductAlertBanner />
          </>
        )}
      </Suspense>
    </Router>
  );
}
